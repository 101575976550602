<template>
  <div class="field-container">
    <label for="fullName">
      Full name
      <input id="fullName" type="text" v-model="fullName" />
    </label>
    <label for="mobile">
      Mobile
      <input type="text" v-model="mobile" />
    </label>
    <label for="phone">
      Office Phone
      <input type="text" v-model="phone" />
    </label>
    <label for="email">
      Email
      <input type="text" v-model="email" />
    </label>
    <label for="address">
      Address
      <input type="text" v-model="address" />
    </label>
    <label for="title">
      Title
      <input type="text" v-model="title" />
    </label>
    <div>
      <div v-for="(cert, i) in certs" :key="i">
        <label :for="`cert-img-${i}`">
          Image
          <input type="text" v-model="cert.img" />
        </label>
        <label :for="`cert-img-${i}`">
          Link
          <input type="text" v-model="cert.link" />
        </label>
        <button @click="removeCert(i)">Remove Cert</button>
      </div>
      <hr />
      <button @click="addCert">Add Cert</button>
      <button @click="copy">Copy Signature</button>
    </div>
  </div>

  <hr style="margin-bottom: 2rem" />

  <table
    style="
      color: rgb(0, 0, 0);
      font-family: 'Open Sans', Arial, sans-serif;
      font-size: 14px;
      line-height: 1.4;
      border-collapse: collapse;
    "
    :width="width"
    cellspacing="0"
    cellpadding="0"
    border="0"
    id="signature"
  >
    <tbody>
      <tr style="border-bottom: 1pt solid #b9b9b9">
        <td style="padding: 0px 10px 10px 10px; vertical-align: middle">
          <img
            src="/logo.png"
            width="75"
            height="75"
            style="vertical-align: middle"
          />
        </td>
        <td style="padding-bottom: 10px; vertical-align: middle">
          <div>
            <b
              ><span style="font-size: 16px">{{ fullName }}</span></b
            >
          </div>
          <div v-if="title">
            <span>{{ title }}</span>
          </div>
        </td>
      </tr>
      <tr>
        <td align="left" style="padding: 10px 10px 0px 10px">
          <img
            src="/apps-websites-cloud.png"
            width="68"
            height="41"
            style="vertical-align: middle"
          />
        </td>
        <td
          colspan="2"
          align="left"
          style="padding-top: 10px; vertical-align: middle"
        >
          <table cellspacing="0" cellpadding="0" border="0">
            <tr>
              <td>
                <span v-if="mobile"
                  ><strong>M:</strong> {{ mobile }}&nbsp;|&nbsp;</span
                >
                <span><strong>T:</strong> {{ phone }}</span>
                <span v-if="!mobile"
                  >&nbsp;|&nbsp;<strong>E: </strong>
                  <a
                    v-bind:href="'mailto:' + email"
                    style="color: #4181d5"
                    target="_blank"
                  >
                    {{ email }}
                  </a></span
                >
              </td>
            </tr>
            <tr>
              <td>
                <span v-if="mobile"
                  ><strong>E: </strong>
                  <a
                    v-bind:href="'mailto:' + email"
                    style="color: #4181d5"
                    target="_blank"
                  >
                    {{ email }} </a
                  >&nbsp;|&nbsp;</span
                ><span><strong>A:</strong> {{ address }}</span>
              </td>
            </tr>
          </table>
        </td>
        <td style="padding: 10px 10px 0px 30px">
          <img
            v-if="false"
            src="/aws.png"
            width="76"
            height="45"
            style="vertical-align: middle"
          />
          <img
            v-if="certs.length === 0"
            src="/aws-partner.png"
            width="62"
            height="62"
            style="vertical-align: middle"
          />
          <span v-else>
            <a
              v-for="(cert, i) in certs"
              :key="i"
              :href="cert.link"
              target="_blank"
              style="vertical-align: middle"
              ><img :alt="cert.link" :src="cert.img" width="76" height="76"
            /></a>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "App",
  components: {},
  data() {
    return {
      fullName: "Dwight Schrute",
      mobile: "",
      phone: "(03) 6231 9110",
      email: "dwight@ionata.com.au",
      address: "32A Davey Street, Hobart",
      title: "Assistant to The Regional Manager",
      certs: [],
    };
  },
  computed: {
    width() {
      return "auto";
      // return 500 + Math.max(0, this.certs.length - 1) * 76;
    },
  },
  methods: {
    addCert() {
      this.certs.push({ img: "", link: "" });
    },
    removeCert(i) {
      this.certs.splice(i, 1);
    },
    copy() {
      let signature = document.querySelector("#signature");
      var range = document.createRange();
      range.selectNode(signature);
      window.getSelection().addRange(range);
      navigator.clipboard.writeText(range.toString()).then(() => {
        alert("Signature has been copied to clipboard.");
      });
      // document.execCommand("copy");
    },
  },
};
</script>

<style>
#app {
  margin-top: 60px;
}
.field-container {
  display: flex;
  flex-direction: column;
}

input {
  display: block;
  margin-bottom: 1rem;
}
</style>
